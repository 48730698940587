import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "author": "benWood",
  "categories": ["rails"],
  "date": "2020-04-10",
  "path": "/blog/strong-parameters-strong-opinions",
  "summary": "A set of conventions to follow when implementing (or fixing) strong parameters in your application.",
  "title": "Strong Opinions About Strong Parameters",
  "image": "./ogp.jpg",
  "seo": {
    "og": {
      "type": "article"
    },
    "twitter": {
      "card": "summary_large_image",
      "creator": "@benjaminwood"
    }
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Rails announced `}<inlineCode parentName="p">{`strong_parameters`}</inlineCode>{` as a replacement for `}<inlineCode parentName="p">{`protected_attributes`}</inlineCode>{` nearly eight years ago. In 2020 many Rails apps have not completed the migration. Others have made the migration, but are worse off than before. Was `}<inlineCode parentName="p">{`strong_parameters`}</inlineCode>{` a bad idea? I don't think so. But like many things, it depends on how you use it.`}</p>
    <h2>{`Strong Opinions`}</h2>
    <h3>{`Instances of `}<inlineCode parentName="h3">{`ActionController:Parameters`}</inlineCode>{` leak outside of the controller`}</h3>
    <p>{`This is undoubtedly the most common mistake I've seen. The reason that `}<inlineCode parentName="p">{`strong_parameters`}</inlineCode>{` is superior to `}<inlineCode parentName="p">{`protected_attributes`}</inlineCode>{` is that it narrows the concern of mass-assignment protection to the controller. With `}<inlineCode parentName="p">{`protected_attributes`}</inlineCode>{`, the entire app had to consider which attributes could mass-assigned, and which could not. This led to anti-patterns like the use of `}<inlineCode parentName="p">{`without_protection`}</inlineCode>{` which allowed developers to skip mass-assignment protection under certain circumstances.`}</p>
    <p>{`The benefit of `}<inlineCode parentName="p">{`strong_parameters`}</inlineCode>{` is lost when `}<inlineCode parentName="p">{`ActionController::Parameters`}</inlineCode>{` are passed outside of a controller and suddenly you have to be concerned about mass-assignment protection everywhere, again.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/lib/payment_processor.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`PaymentProcessor`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`initialize`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@params`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` params
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`process`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ... payment processing logic, etc`}</span>{`
      `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Payment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`create`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`payment_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`payment_params`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@params`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:payment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:payment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:attrs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/payments_controller.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`PaymentsController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationController`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`create`}</span></span>{`
      payment `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PaymentProcessor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` payment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`process
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <br />
    <p><strong parentName="p">{`Strong Opinion`}</strong>{`: Parameters should be permitted at the controller level. If you pass some params on to another object (like a service class), first permit the params that are allowed for mass-assignment, then call `}<inlineCode parentName="p">{`to_h`}</inlineCode>{` on it to convert the object to a good ol' `}<inlineCode parentName="p">{`ActiveSupport::HashWithIndifferentAccess`}</inlineCode>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/lib/payment_processor.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`PaymentProcessor`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`initialize`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`payment_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token variable"
          }}>{`@payment_params`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` payment_params
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`process`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ... payment processing logic, etc`}</span>{`
      `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Payment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`create`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token variable"
          }}>{`@payment_params`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/payments_controller.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`PaymentsController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationController`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`create`}</span></span>{`
      payment `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`PaymentProcessor`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`payment_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`to_h`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` payment`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`process
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`payment_params`}</span></span>{`
      params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:payment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:payment`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:attrs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <hr></hr>
    <h3>{`Use of strong parameters where mass assignment is not being performed`}</h3>
    <p>{`The Rails community is pretty split on this one. Should you `}<em parentName="p">{`always`}</em>{` permit parameters? Or only permit parameters when mass-assignment is being performed? Here's an example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/users_controller.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UsersController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationController`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`special_update_action`}</span></span>{`
      user `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`user_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`special_attribute `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` user_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:special_attribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`save
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`user_params`}</span></span>{`
      params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:user`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
        `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:special_attribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:user`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:attrs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:including`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <br />
    <p><strong parentName="p">{`Strong Opinion`}</strong>{`: Don't complicate your controllers by permitting params when mass assignment is `}<em parentName="p">{`not`}</em>{` being performed.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/users_controller.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UsersController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationController`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`special_update_action`}</span></span>{`
      user `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`User`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Notice that special_attribute is pulled off of \`params\` directly`}</span>{`
      user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`special_attribute `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:special_attribute`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`

      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` user`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`save
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <hr></hr>
    <h3>{`Order of operations when mutating a params object`}</h3>
    <p>{`Remember, `}<inlineCode parentName="p">{`strong_parameters`}</inlineCode>{` is meant to sanitize `}<em parentName="p">{`externally-provided`}</em>{` parameters. If you add a key to the params object, then call `}<inlineCode parentName="p">{`permit`}</inlineCode>{`, you'll end up having to permit the parameter you just added.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/credit_cards_controller.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`CreditCardsController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationController`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`update`}</span></span>{`
      credit_card `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`CreditCard`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:top_secret_token`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`TopSecretToken`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` credit_card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`update`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`credit_card_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`credit_card_params`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Notice the addition of top_secret_token is included in this`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# scenario so that it can be mass-assigned above`}</span>{`
      params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:credit_card`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
        `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:top_secret_token`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:credit_card`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:attrs`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <br />
    <p><strong parentName="p">{`Strong Opinion`}</strong>{`: Permit your params `}<em parentName="p">{`before`}</em>{` mutating the object. This allows you to permit only the externally-provided parameters, then modify the object as you see fit.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/credit_cards_controller.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`CreditCardsController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationController`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`update`}</span></span>{`
      credit_card `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`CreditCard`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`find`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      assignable_params `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` credit_card_params
      assignable_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:top_secret_token`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`TopSecretToken`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`args`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` credit_card`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`update`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`assignable_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`credit_card_params`}</span></span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Notice that top_secret_token does *not* need to be included`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# here because it is assigned to the (permitted) return value`}</span>{`
      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# of credit_card_params`}</span>{`
      params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:credit_card`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:credit_card`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:attrs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <hr></hr>
    <h3>{`Defining permitted attributes multiple (sometimes many) times`}</h3>
    <p>{`With `}<inlineCode parentName="p">{`protected_attributes`}</inlineCode>{`, the model provided a central place to specify which attributes were permitted for mass-assignment. With `}<inlineCode parentName="p">{`strong_parameters`}</inlineCode>{`, permitted parameters are often defined multiple times for the same resource in different controllers. This is not DRY and it is not maintainable. Add in consideration for `}<inlineCode parentName="p">{`accepts_nested_attributes_for`}</inlineCode>{` and you're maintaining countless lists of the same parameters.`}</p>
    <p><em parentName="p">{`Note: In the previous examples parameters were permitted directly in the controller as seen below. This was done for simplicity's sake, and also on the basis that no other controllers duplicated the same set of permitted parameters.`}</em></p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/departments_controller.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DepartmentsController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationController`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`create`}</span></span>{`
      department `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Department`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`department_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` department`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`save
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`department_params`}</span></span>{`
      params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:department`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
        `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:department`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:attrs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
        employees_attributes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
          `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:employee`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:attrs`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
      `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/special/departments_controller.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`module`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Special`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DepartmentsController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationController`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`create`}</span></span>{`
        department `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Department`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`department_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` department`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`save
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{`

      `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Copy pasta`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`department_params`}</span></span>{`
        params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:department`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
          `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:department`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:attrs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
          employees_attributes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
            `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:employee`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:attrs`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <br />
    <p><strong parentName="p">{`Strong Opinion`}</strong>{`: Duplicated parameters should be permitted in a module that can be included wherever it is needed. Furthermore, nested attributes should not be redefined multiple times. Here's an example:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ruby"
    }}><pre parentName="div" {...{
        "className": "language-ruby"
      }}><code parentName="pre" {...{
          "className": "language-ruby"
        }}>{`
  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/concerns/strong_parameters/employee.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`module`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Concerns`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`module`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`StrongParameters`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`module`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Employee`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`employee_params`}</span></span>{`
          params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:employee`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permitted_attrs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`self`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`permitted_attrs`}</span></span>{`
          `}<span parentName="code" {...{
            "className": "token string"
          }}>{`%i(some employee attrs)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/concerns/strong_parameters/department.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`module`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Concerns`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`module`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`StrongParameters`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`module`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Department`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`department_params`}</span></span>{`
          params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`require`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token symbol"
          }}>{`:department`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permit`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permitted_attrs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`self`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`.`}</span><span parentName="span" {...{
              "className": "token function"
            }}>{`permitted_attrs`}</span></span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
            `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:some`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:department`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:attrs`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` employees_attributes`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span>{`
              `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:id`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token symbol"
          }}>{`:_destroy`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`*`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Concerns`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`StrongParameters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Employee`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`permitted_attrs`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/departments_controller.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DepartmentsController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationController`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`include`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Concerns`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`StrongParameters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Department`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`create`}</span></span>{`
      department `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Department`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`department_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` department`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`save
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/special/departments_controller.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`module`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Special`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DepartmentsController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationController`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`include`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Concerns`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`StrongParameters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Department`}</span>{`

      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`create`}</span></span>{`
        department `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Department`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`department_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` department`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`save
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
          `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`

  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# FILE: app/controllers/employees_controller.rb`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`EmployeesController`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`ApplicationController`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`include`}</span>{` `}<span parentName="code" {...{
            "className": "token constant"
          }}>{`Concerns`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`StrongParameters`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token constant"
          }}>{`Employee`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`def`}</span>{` `}<span parentName="code" {...{
            "className": "token method-definition"
          }}><span parentName="span" {...{
              "className": "token function"
            }}>{`create`}</span></span>{`
      employee `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` employee`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`employee_params`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` employee`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`save
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`
      `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span></code></pre></div>
    <h2>{`Summary`}</h2>
    <p>{`We follow these practices at Hint, and it's helped us (and our clients) a lot. Does your organization need help wrangling strong parameters? We can help. Ping me on twitter: @benjaminwood.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      